.left-column {
	width: 58%;
	display: flex;
	/* position: absolute; */
	justify-content: center;
	align-items: center;

	.landing {
		position: absolute;
	}

	.lettria-logo {
		position: absolute;
		left: 64px;
		top: 48px;
		width: 155px;
		height: 44px;
		// width: 100%;
	}
}

.right-column {
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 80px;

	.verify-form {
		width: 100%;
	}

	.verify-google {
		color: white;
		display: block;
		width: 100%;
		height: 40px;
		margin-top: 24px;
		margin-bottom: 34px;
	}

	.inputs {
		padding: 8px 12px;
		border: 1px solid #d9d9d9;
		border-radius: 8px;
		width: 100%;
		height: 40px;
	}

	.inputs-code {
		// padding: 8px;
		border: 1px solid #d9d9d9;
		border-radius: 8px;
		width: 65px;
		height: 40px;
		text-align: 'center'
	}

	.no-card {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 25px;
		// text-align: center;
		color: #6b4eb6;

		.no-card-link {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			/* identical to box height, or 157% */

			text-align: center;

			/* Accent/5 */

			color: #6b4eb6;
		}
	}

	// span {
	// 	font-style: normal;
	// 	font-weight: 600;
	// 	font-size: 14px;
	// 	line-height: 22px;
	// 	/* identical to box height, or 157% */

	// 	text-align: right;

	// 	/* Character/Secondary */

	// 	color: #676767;
	// }

	.verify-form-button {
		span {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			/* identical to box height, or 150% */

			text-align: center;
			letter-spacing: 0.1px;

			/* Character/Inverse / Primary */

			color: #ffffff;
		}
		color: white;

		display: block;
		margin: auto;
		width: 100%;
		height: 40px;
	}

	// Link {
	// 	color: #6b4eb6;
	// 	text-decoration: underline;
	// }

	.link {
		color: #6b4eb6;
		text-decoration: underline;
		font-weight: bold;
	}
}
