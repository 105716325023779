@import "themes/variables.less";

.action {
	cursor: pointer;
	&:hover {
		color: @color-primary;
	}
}

.global-align {
	display: flex;
	align-items: center;
}

.no-padding {
	padding: 0 !important;
}

.badge {
	&:extend(.global-align);
	width: 16px;
	height: 16px;

	background: rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	color: rgba(0, 0, 0, 0.45);
}

.btn-link.ant-btn,
.btn-link .ant-btn {
	&:extend(.global-align);
	border: none;
	padding: 4px 4px;
	box-shadow: none;
	background: transparent;
	&.ant-btn-default:disabled {
		border: none !important;
		background-color: transparent !important;
	}
}

.ant-btn.btn-link.span-button {
	&:not(:hover) {
		color: var(--text-text-secondary, rgba(0, 0, 0, 0.65));
	}
	text-align: center;
	/* Button */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.text-link {
	&:extend(.global-align);
	color: #8650ff;
	cursor: pointer;
}
