@import "themes/variables";

.item-list-container {
	box-shadow: @box-shadow-base;
	border-radius: 8px;

	.item-list-table {
	}
	.item-list-pagination {
		padding: 12px 6px;
	}
}
