@padding-width: 40px;
.container {
	padding: @padding-width;
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 100%;

	@media only screen and (min-width: 0) and (max-width: 980px) {
		max-width: calc(594px + @padding-width);
	}
	@media only screen and (min-width: 981px) and (max-width: 1280px) {
		max-width: calc(594px + @padding-width);
	}
	@media only screen and (min-width: 1281px) and (max-width: 1440px) {
		max-width: calc(640px + @padding-width);
	}
	@media only screen and (min-width: 1441px) and (max-width: 1728px) {
		max-width: calc(738px + @padding-width);
	}
	@media only screen and (min-width: 1729px) {
		max-width: calc(738px + @padding-width);
	}
}
.ant-layout-has-sider .container,
.container-with-sider {
	@media only screen and (min-width: 0) and (max-width: 980px) {
		max-width: calc(658px + @padding-width);
	}
	@media only screen and (min-width: 981px) and (max-width: 1280px) {
		max-width: calc(958px + @padding-width);
	}
	@media only screen and (min-width: 1281px) and (max-width: 1440px) {
		max-width: calc(1118px + @padding-width);
	}
	@media only screen and (min-width: 1441px) and (max-width: 1728px) {
		max-width: calc(1126px + @padding-width);
	}
	@media only screen and (min-width: 1729px) {
		max-width: calc(1126px + @padding-width);
	}
}

.large-container {
	padding: @padding-width;
}
