@import "themes/variables.less";

*:focus {
	outline: none;
}
th {
	font-weight: normal !important;
	font-size: 12px;
}
.padding-horizontal {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.padding-vertical {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.padding-xs {
	padding: 8px;
}
.padding-sm {
	padding: 12px;
}
.padding,
.padding-md {
	padding: 24px;
}
.padding-lg {
	padding: 40px;
}
.padding-sider {
	padding: 24px 40px;
}

.padding-md-lg {
	padding: 24px 40px;
}
@keyframes spinning-logo-animation {
	// from {
	// 	transform: rotate(0deg);
	// }
	// to {
	// 	transform: rotate(360deg);
	// }
	0% {
		transform: rotate(0deg);
	}

	20% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinning-logo {
	width: 55px;
	height: 55px;
	animation: spinning-logo-animation 5s cubic-bezier(0.5, 1, 0.5, 1) infinite;
}

.shadow {
	box-shadow: 0px 0px 10px rgba(240, 239, 242, 0.8);
}
.border {
	border: 1px solid @color-border-secondary;
	border-radius: 8px;
}
.border-radius {
	border-radius: 8px;
	overflow: hidden;
}

.no-margin {
	margin: 0px !important;
}

.underline {
	text-decoration: underline;
}

.project-label-icon {
	width: 14px;
	height: 14px;
	border-radius: 4px;
	background-color: grey;
	display: inline-flex;
	align-items: center;
}
.project-label-icon-lg {
	&:extend(.project-label-icon);
	width: 20px;
	height: 20px;
}
.project-label-icon-sm {
	&:extend(.project-label-icon);
	width: 12px;
	height: 12px;
}

.clickable {
	cursor: pointer;
}

.caracter-icon {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	cursor: pointer;
	line-height: 16px;
	text-align: center;
	font-size: 18px;
	border: 1px solid #d9d9d9;
	color: #1f1f1f;
}

.ressource-list-container {
	// max-width: 1400px;
	// padding: 2rem 3rem;
	margin: auto;
	width: 100%;
}
.table {
	// overflow: hidden;
	// border-radius: 8px;
	background-color: rgb(255, 255, 255);
	th {
		background: white !important;
		color: #676767 !important;
		// border-radius: 0.5rem !important;
		border-right: none;
		&::before {
			background-color: transparent !important;
		}
	}
	tr > th:nth-child(1) {
		padding-left: 18px !important;
	}
	tr > td:nth-child(1) {
		padding-left: 18px !important;
	}
	.ant-table-selection-column {
		padding: 8px 8px 8px 18px !important;
	}
	.ant-table-column-sorters::after {
		padding: 8px !important;
	}
	.ant-pagination {
		padding: 0 12px;
	}
	.row {
		cursor: pointer;
	}
}
.table-bordered {
	.table;
	border: 1px solid #f0f0f0;
	&.no-pagination {
		border-bottom-color: transparent;
	}
}

.btn-attach {
	color: #6b4eb6;
}

.icon-before,
.icon-after {
	vertical-align: middle;
}

.icon-after {
	margin-left: 8px;
}
.icon-before {
	margin-right: 8px;
}

.informations-container {
	.info-container {
		display: flex;
		.label-container {
			width: 40%;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
		}
		.data-container {
			width: 60%;
		}
	}
	.section-title-extra {
		float: right;
		// margin-top: -27px;
	}
}

.informations-drawer {
	// border: 1px solid #f0f0f0;
	max-height: calc(100vh - 145px);
	overflow-y: auto;
	h5 {
		margin-top: 0;
	}
	.drawer-header {
		border-bottom: 1px solid #f0f0f0;
		padding: 16px;

		.drawer-header-close,
		.drawer-header-title {
			display: inline-block;
		}
		.drawer-header-title {
			text-transform: capitalize;
			margin-top: 0px;
			margin-bottom: 0px;
		}
		.drawer-header-close {
			float: right;
			font-size: 19px;
			opacity: 0.45;
			margin-top: 5px;
		}
	}
	.drawer-section {
		padding: 16px;
	}
}

.hover {
	cursor: pointer;
	&:hover {
		transition: 0.1s;
		background-color: #f5f5f5;
	}
	&:active {
		transition: 0.1s;
		background-color: #e6e6e6;
	}
}
