@import "themes/variables.less";
.full-screen {
	min-height: 100%;
	width: 100%;
	.full-screen__close {
		float: right;
		padding: 24px 40px;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 4px;
		z-index: 9999;

		svg {
			width: 16px;
			height: 16px;
		}
	}
	.full-screen__header {
		width: 100vw;
		position: fixed;
		border-bottom: 1px solid @color-border;
		z-index: 10;
		background: white;
		.full-screen__header__content {
			padding: 0 40px;
			height: 72px;
			display: inline-flex;
			align-items: center;
			width: 100%;
			// width: calc(100% - 80px);
			box-sizing: border-box;
		}
	}
	.full-screen__content {
		padding-top: 72px;
		position: relative;
		// z-index: 1;
		height: calc(100vh - 72px);
	}
	.empty-state {
		padding-top: 18vh;
	}
}

.full-screen-header {
	padding: 0 40px;
	height: 72px;
	display: inline-flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid @color-border;
	z-index: 10;
	background: white;
}
