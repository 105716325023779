@import "themes/variables.less";

.ant-radio-wrapper {
	.ant-radio {
		margin-right: 8px;
	}
}

.radio-list-item {
	width: 100%;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	cursor: pointer;

	.radio-list-content {
		padding: 24px;
	}
}
.radio-list-item + .radio-list-item {
	margin-top: 16px;
}

.radio-list-item__selected {
	background: #ffffff;
	border: 1px solid #8650ff;
	box-shadow: 0px 0px 10px rgba(240, 239, 242, 0.8);
	border-radius: 8px;
}

.radio-list-item__disabled {
	// opacity: 0.5;
	cursor: not-allowed;
}

// .ant-tooltip-inner {
// 	background-color: white !important;
// 	div {
// 		color: @color-text !important;
// 	}
// }
