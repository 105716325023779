@import "themes/variables.less";

.tag-light-red,
.tag-light-green,
.tag-light-blue,
.tag-light-yellow {
	color: @color-text;
}
.tag-light-red {
	background: @color-light-red;
	border: 1px solid @color-border-light-red;
}

.tag-light-green {
	background: @color-light-green;
	border: 1px solid @color-border-light-green;
}

.tag-light-blue {
	background: @color-light-blue;
	border: 1px solid @color-border-light-blue;
}

.tag-light-yellow {
	background: @color-light-yellow;
	border: 1px solid @color-border-light-yellow;
}

.tag-light-purple {
	background: @color-light-purple;
	border: 1px solid @color-border-light-purple;
}

.tag-red {
	background: #f8eef0;
	// border: 1px solid #be2e2c;
	color: #be2e2c;
}

.tag-green {
	background: #f8ffef;
	// border: 1px solid #f8ffef;
	color: #72c240;
}

.tag-blue {
	background: #e9f4fe;
	// border: 1px solid #285ad6;
	color: #285ad6;
}

.tag-orange {
	background: #fef7e8;
	// border: 1px solid #c6712b;
	color: #c6712b;
}

.tag-yellow {
	background: #feffe8;
	// border: 1px solid #ceb23c;
	color: #ceb23c;
}

.tag-purple {
	background: #f7f0fe;
	// border: 1px solid #4d20a4;
	color: #4d20a4;
}

.default {
	background: darkgray;
}

.red {
	background: #be2e2c;
}

.green {
	background: #72c240;
}

.blue {
	background: #285ad6;
}

.orange {
	background: #c6712b;
}

.yellow {
	background: #ceb23c;
}

.purple {
	background: #4d20a4;
}

// .tag-lg {
// 	padding: 5px 8px;
// 	border-radius: 8px;
// 	font-size: 13px;
// }
