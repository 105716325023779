@import "themes/variables";
.route-column-primary {
	margin-top: 2px;
	padding: 10px;
	border-radius: 5px;
	color: white;
	// background-color: @color-primary;
}

.route-column-link {
	margin-top: 2px;
	padding: 10px;
	border-radius: 5px;
	color: white;
	// background-color: @success-color;
}
