.ant-layout-sider {
	.user-dropdown {
		background-color: white;
		position: absolute;
		left: 237px;
		// width: 117px;
		// height: 78px;
		overflow-y: auto;
		z-index: 999999;
		bottom: 45px;
		padding: 4px;
		line-height: @default-line-height;
		width: 150px;

		.dropdown-item {
			border-radius: 8px;
			padding: 8px 16px;
			cursor: pointer;
			&:hover {
				background: @color-primary-bg-hover;
			}
		}
	}
	.user-menu {
		padding: 8px 16px;
		border-radius: 8px;
		cursor: pointer;
		// margin-top: 24px;
		// background-color: $primary-color !important;

		// & > * {
		// 	display: inline-flex;
		// }
		&:hover {
			background-color: #f0f2f5;
		}
		.user-icon {
			margin-right: 8px;
		}

		.user-menu__user {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			.user-menu__user-name {
				width: 120px;
			}
		}
		.user-menu__icon {
			height: 6px;
		}
	}
}
