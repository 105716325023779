@import "themes/variables.less";

.react-markdown-container {
	font-weight: normal;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
	}
	h1 {
		font-size: 24px;
		font-weight: 800;
	}
	h2 {
		font-size: 22px;
	}
	h3 {
		font-size: 20px;
	}
	h4 {
		font-size: 18px;
	}
	h5 {
		font-size: 16px;
	}
	th,
	td {
		border: 1px solid black;
		text-align: center;
		padding: 6px;
	}
	code {
		color: black;
		background: #efefef;
		border-radius: 4px;
		padding: 2px 4px;
		min-width: 70px;
		font-family: courier;
		font-size: 12px;
	}
	* {
		border-color: @color-border !important;
	}
}
