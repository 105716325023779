.page-content {
	// padding: 40px 80px;
	// max-width: 1042px;
	// margin: 0 auto;
}

.space-between {
	display: flex;
	justify-content: space-between;
	// align-items: baseline;
}
.full-width {
	width: 100%;
}
.align-center {
	align-items: center;
}
.justify-center {
	justify-content: center;
}

.no-scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.inline-flex {
	display: inline-flex;
}

.flex {
	display: flex;
}

.inline-block {
	display: inline-block;
}

.two-columns {
	display: flex;
	align-items: flex-start;
	.small-column {
		width: 32%;
	}
	.large-column {
		width: 68%;
	}
}

.bg-white {
	background-color: white;
}

.spinner {
	width: 100%;
	padding-top: 50%;
}
.spinner-container {
	position: relative;
	width: 100%;
	height: 100%;
	// .ant-spin {
	// 	width: 100%;
	// 	padding-top: 50%;
	// }
}

.right-sider-layout {
	height: 100vh;
	width: 100vw;
	// display: flex;

	.primary-col {
		height: 100%;
		flex-grow: 1;
		max-width: 100%;
		min-width: 0;
	}
	.secondary-col {
		background: white;

		height: 100%;
		flex-basis: 500px;
		min-width: 500px;

		box-shadow: 0px 5px 8px rgba(240, 239, 242, 0.8),
			0px 10px 40px rgba(240, 239, 242, 0.8);
		&.collapsed {
			min-width: 0;
			max-width: fit-content;
		}
		.secondary-col-collapsed {
			padding: 0 18px;
			height: 72px;
			display: inline-flex;
			align-items: center;
		}
	}

	.right-sider-layout-container {
		height: calc(100vh - 72px);
		width: 100vw;
		display: flex;

		.content-viewer {
			.content-viewer-header {
				padding: 20px 40px;
			}
			.content-viewer-content {
				// flex-flow: unset;
				// width: calc(100% - 80px);
				// padding: 0 40px;
				.ant-table-header > table,
				.ant-table-body > table {
					table-layout: unset !important;
				}
			}
		}
	}

	.asset-list-detail-panel-container {
		display: flex;
		height: 100%;

		.option-editor-header {
		}
		.asset-list-detail-panel {
			flex-grow: 1;
			min-height: 0;
			transition: height 0.3s ease-in-out;

			.option-editor-label {
				padding: 0 40px;
			}

			.option-editor-annotation {
				height: calc(100vh - 290px);
				overflow-y: auto;

				.option-editor-annotation-item {
					padding: 24px;
					border-bottom: 1px solid #f0f0f0;
					cursor: pointer;
					&:hover {
						background: rgba(0, 0, 0, 0.02);
					}
					&.selected {
						background: rgba(0, 0, 0, 0.04);
					}
					.items {
						padding-left: 10px;
						border-left: 1px solid #f0f0f0;

						font-style: italic;
						font-family: "Inter";
						font-weight: 400;
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
	}
}
