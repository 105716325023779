.signup-container {
	background: url("../img/background.svg");
	background-size: cover;
	min-height: 100vh !important;

	.signup-logo {
		position: absolute;
		top: 10vh;
		margin: auto;
		width: 200px;
		left: calc(50vw - 100px);
	}

	.left-column {
		width: 58%;
		display: flex;
		/* position: absolute; */
		justify-content: center;
		align-items: center;

		.landing {
			position: absolute;
		}

		.lettria-logo {
			position: absolute;
			left: 64px;
			top: 48px;
			width: 155px;
			height: 44px;
			// width: 100%;
		}
	}

	.right-column {
		background: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 48px;

		.google-logo {
			// position: absolute;
			top: 10vh;
			margin: auto;
			width: 14.11px;
			height: 14.11px;
			left: calc(50vw - 100px);
			margin-right: 4px;
		}

		// li{
			.validate {
				color: #00a503;
				list-style: url("../img/CheckCircle.svg");
			}

			.not-validate {
				color: #7e7e7e;
				list-style: url("../img/Circle.svg");
			}
		// }

		.signup-form {
			width: 100%;
		}

		.signup-google {
			span {
				// color: white;
			}
			color: white;
			display: block;
			width: 100%;
			height: 40px;
			margin-top: 24px;
			margin-bottom: 34px;
		}

		.inputs {
			padding: 8px 12px;
			border: 1px solid #d9d9d9;
			border-radius: 8px;
			width: 100%;
			height: 40px;
		}

		.no-card {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #676767;
			padding: 4px;
		}

		span {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			/* identical to box height, or 157% */

			text-align: right;

			/* Character/Secondary */

			color: #676767;
		}

		.signup-form-button {
			span {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				/* identical to box height, or 150% */

				text-align: center;
				letter-spacing: 0.1px;

				/* Character/Inverse / Primary */

				color: #ffffff;
			}
			color: white;

			display: block;
			margin: auto;
			width: 100%;
			height: 40px;
			&:disabled {
				// color: red;
				span {
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;

					text-align: center;
					letter-spacing: 0.1px;

					color: #676767;
				}
			}
		}

		.link {
			color: #6b4eb6;
			text-decoration: underline;
			font-weight: bold;
		}
	}

	.signup-card {
		background-color: white;
		position: absolute;
		margin: auto;
		top: calc(50vh - (276px / 2));
		left: calc(50vw - (464px / 2));

		padding: 32px;

		.title {
			text-align: center;
		}
		.or-signup {
			color: #8b8b8b;
			text-align: center;
		}
		.button-submit {
			margin: auto;
			display: block;
			margin-top: 24px;
		}
	}

	.ant-form-item-required {
		&::before {
			display: none !important;
		}
	}
}
