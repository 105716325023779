@import "../variables.less";

.break-word {
	white-space: break-spaces;
	word-break: break-word;
}

.text-tertiary {
	&,
	* {
		color: @color-text-tertiary;
	}
}

.text-align-left {
	&,
	* {
		text-align: left;
	}
}

.text-align-center {
	&,
	* {
		text-align: center;
	}
}

.text-align-right {
	&,
	* {
		text-align: right;
	}
}

.normal-weight {
	&,
	* {
		font-weight: normal !important;
	}
}

.no-padding {
	& {
		padding: 0 !important;
	}
}

.text-danger {
	color: #eb0004;
}

// DEPRECATED: Use the Typography.Text component

.text-xs {
	&,
	* {
		font-size: 12px;
	}
}
.text-sm {
	&,
	* {
		font-size: 13px;
	}
}
.text-md {
	&,
	* {
		font-size: 14px;
		line-height: 24px;
	}
}

.text-lg {
	&,
	* {
		font-size: 16px;
	}
}

.text-xlg {
	&,
	* {
		font-size: 18px;
	}
}

.text-primary {
	&,
	* {
		color: @color-primary;
	}
}

.text-secondary {
	&,
	* {
		color: @color-text-secondary;
	}
}

.text-success {
	&,
	* {
		color: @color-success;
	}
}

.text-info {
	&,
	* {
		color: @color-info;
	}
}

.text-warning {
	&,
	* {
		color: @color-warning;
	}
}

.text-error {
	&,
	* {
		color: @color-error;
	}
}
