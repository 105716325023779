@import "./themes/index";
html {
	font-size: 100%;
}

&:root {
	font-family: "Inter", serif !important;
	--animate-duration: 0.5s;
}

body {
	min-height: 100vh;
	margin: 0;
	font-weight: 400;
	line-height: @default-line-height;
	// 	"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
	// 	"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Inter", sans-serif;

	background: @color-bg-layout;
	*::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0);
	}
	*::-webkit-scrollbar-active {
		background-color: lightgrey;
	}
	*::-webkit-scrollbar {
		height: 4px;
		width: 4px;
		background-color: rgba(0, 0, 0, 0);
	}
	*::-webkit-scrollbar-thumb {
		border-radius: 4px !important;
		background-color: rgba(11, 37, 64, 0.2);
	}
	*::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
}

a {
	color: black;
	// &:hover {
	// 	color: #9665e3;
	// }
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
