@import "themes/variables.less";

.ant-table-wrapper .ant-table-column-title {
	z-index: unset !important;
}

.ant-progress-bg {
	background-color: @color-primary !important;
}

.ant-typography-secondary {
	color: #676767 !important;
}

// .ant-btn-primary {
// 	background: @color-primary-bis;
// 	color: @color-text;
// 	&:hover {
// 		background: @color-primary-bis-hover !important;
// 		color: @color-text !important;
// 	}
// }

.ant-table-column-sorter-down.active {
	color: @color-primary !important;
}
.ant-checkbox {
	&:hover,
	&:active {
		.ant-checkbox-inner {
			border-color: @color-primary !important;
		}
	}
}
.ant-table-cell-row-hover {
	a {
		&:hover,
		&:active {
			color: @color-primary !important;
		}
	}

	.ant-table-cell {
		a,
		strong {
			border-color: @color-primary !important;

			&:hover {
				border-color: @color-primary !important;
			}
		}
	}
}
.ant-table-row-selected > td {
	background-color: @color-primary-bg !important;
}
.ant-checkbox-inner {
	&:hover,
	&:active {
		border-color: @color-primary !important;
	}
}

.ant-table-column-sorter-up.active {
	svg {
		color: @color-primary !important;
	}
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: @color-primary !important;
	border-color: @color-primary !important;
}
.ant-checkbox-indeterminate {
	.ant-checkbox-inner {
		&::after {
			background-color: @color-primary !important;
		}
	}
}

.ant-layout-header {
	// border-bottom: 2px solid @color-border;
	// height: auto !important;
	// line-height: 14px !important;
	// padding: 18px 40px;
}

.collapse-visible-overflow {
	overflow: visible !important;
}

.ant-layout {
	background-color: @color-bg-layout !important;
}

.ant-card-bordered {
	border-radius: 8px;
	// overflow: hidden;
}

.ant-tag {
	// border-radius: 8px;
	// overflow: hidden;
	border: none;
	// background-color: @color-bg-container;
	// color: @color-text-secondary;
}

.ant-select > .ant-select-selector {
	border-radius: 0.5rem !important;
	overflow: hidden;
}

// .ant-input-affix-wrapper {
// 	border-radius: 0.5rem !important;
// 	overflow: hidden;
// }

.ant-input {
	border-radius: 0.5rem !important;
	overflow: hidden;
}

.ant-input-group {
	.ant-input-affix-wrapper,
	.ant-input {
		border-bottom-left-radius: 0.5rem !important;
		border-top-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0rem !important;
		border-top-right-radius: 0rem !important;
	}
	.ant-input-group-addon > button {
		border-bottom-right-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
}

// input,
// textarea {
// 	border-radius: 0.5rem !important;
// 	overflow: hidden;
// }

.ant-btn {
	border-radius: 8px;
	overflow: hidden;
	& > svg {
		vertical-align: middle;
	}
}
.ant-tag {
	& > svg {
		margin-right: 8px;
		vertical-align: middle;
	}
}

.ant-collapse {
	border-radius: 8px;
	overflow: hidden;
}

.ant-table-thead {
	th {
		padding: 12px 12px !important;
	}
}

.ant-table-column-title {
	z-index: auto;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
	z-index: auto !important;
}

.ant-layout-sider {
	border-right: 1px solid #f0f0f0;
}

.ant-modal-title {
	font-weight: bold !important;
	font-size: 18px !important;
}

.ant-message {
	left: unset !important;
	transform: unset !important;
	top: unset !important;
	position: fixed !important;
	bottom: 10px !important;
	right: 10px !important;
	width: fit-content !important;

	.ant-message-notice {
		// bottom: 20px !important;
		// position: fixed !important;
		// left: 20px !important;
	}
	.request-response-message {
		// margin-left: auto;
		// margin-right: 200px;
		// width: fit-content;
		.ant-message-notice-content {
			// background: #dcc6ff;
			// color: white;
		}
	}
}
.ant-tree-switcher {
	padding-top: 2px !important;
}
// .ant-input[disabled] {
// 	// background-color: rgba(0, 0, 0, 0.3) !important;
// 	// border-color: rgba(0, 0, 0, 0.01) !important;
// 	// color: rgba(0, 0, 0, 0.3) !important;
// }

// .ant-select-disabled {
// 	.ant-select-selector {
// 		// background-color: rgba(0, 0, 0, 0.3) !important;
// 		// border-color: rgba(0, 0, 0, 0.01) !important;
// 		// color: rgba(0, 0, 0, 0.3) !important;
// 	}
// }
