@import "themes/variables";

.number-icon {
	background-color: @color-bg-container;
	color: @color-text-secondary;

	margin-left: auto;
	margin-right: 0;
	line-height: 20px;
	height: 20px;
	width: 20px;
	text-align: center;
	border-radius: 50%;
	display: block;

	&.disabled {
		background-color: rgba(0, 0, 0, 0.15);
		color: rgba(0, 0, 0, 0.25);
	}
}
.ant-menu-item-selected {
	.number-icon {
		background-color: #d8cdf6;
		color: black;
	}
}

.number-icon-primary {
	background-color: @color-primary-bg;
	color: @color-primary;
	margin-left: auto;
	margin-right: 0;
	line-height: 20px;
	height: 20px;
	// width: 20px;
	width: fit-content;
	padding: 2px 8px;
	text-align: center;
	border-radius: 24px;
	font-size: 12px;
	font-weight: normal;
}
